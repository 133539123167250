import styles from "./styles.module.scss";
import GenericCard from "../../GenericCard";
import Title from "../../Title";
import { methodologies } from "../../../shared/utils/methodologies";
import Cta from "../../Cta";

export default function Methodology() {
   return (
      <section className={styles.container}>
         <div className={styles.content}>
            <Title>NOSSA METODOLOGIA</Title>

            <p className="paragraph">
               Entenda como oferecemos um ensino de excelência para formar os melhores
               profissionais do mercado em tempo recorde.
            </p>

            <div className={styles.methods}>
               {methodologies.map((method, index) => (
                  <GenericCard
                     content={{
                        title: method.title,
                        paragraph: method.paragraph,
                        image: method.image,
                     }}
                     key={`${method.title + index}`}
                     background="#001e32"
                     textColor="#fff"
                  />
               ))}
            </div>

            <Cta
               url={`/curriculo`}
               maxWidthDesktop="20.313rem"
               color="blue"
               passParams
               text="Saiba mais"
               isLocalUrl
            />
         </div>
      </section>
   );
}
