import { useEffect, useState } from "react";
import styles from "./styles.module.scss";
import { useParams } from "../../hooks/Params";

export default function FloatLinks() {
  const [isVisible, setIsVisible] = useState(false);
  const { params } = useParams();

  function watchScroll() {
    if (window.scrollY > 450) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  }

  useEffect(() => {
    if (window.innerWidth < 1100) {
      window.addEventListener("scroll", watchScroll);
    } else {
      setIsVisible(true);
    }
    return () => window.removeEventListener("scroll", watchScroll);
  }, []);

  return (
    <div
      className={`${styles.container} ${isVisible ? styles.visible : styles.hidden
        }`}
    >
      <a
        className={styles.subScriptionButton}
        href={`https://inscricao.kenzie.com.br/${params}`}
      >
        Entrar na Lista de Espera
        <svg
          width="56"
          height="16"
          viewBox="0 0 56 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M55.2765 8.42196C55.667 8.03143 55.667 7.39827 55.2765 7.00774L48.9125 0.643781C48.522 0.253257 47.8888 0.253257 47.4983 0.643781C47.1078 1.03431 47.1078 1.66747 47.4983 2.05799L53.1551 7.71485L47.4983 13.3717C47.1078 13.7622 47.1078 14.3954 47.4983 14.7859C47.8888 15.1764 48.522 15.1764 48.9125 14.7859L55.2765 8.42196ZM0.388794 8.71484L54.5694 8.71485L54.5694 6.71485L0.388794 6.71484L0.388794 8.71484Z"
            fill="white"
          />
        </svg>
      </a>
    </div>
  );
}
