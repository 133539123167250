export const methodologies = [
  {
    title: "Aprenda na prática",
    paragraph:
      "Com um ensino baseado em projetos, os alunos Kenzie evoluem com base nas demandas do mercado. Ao colocar a mão na massa, o aprendizado torna-se muito mais rápido.",
    image: "/images/icon-pratice.svg",
  },
  {
    title: "Suporte em tempo real",
    paragraph:
      "Um dos diferenciais da Kenzie é que vamos bem além das aulas gravadas. O contato ágil entre alunos, instrutores e equipe é parte da metodologia. Assim, as dúvidas são sempre respondidas no menor tempo possível.",
    image: "/images/icon-support.svg",
  },
  {
    title: "Conteúdo atualizado",
    paragraph:
      "Aprenda tudo que mais interessa para entrar no mercado. Nosso currículo é atualizado e baseado no que as empresas realmente precisam. Em menos de um ano você pode se tornar um programador completo.",
    image: "/images/icon-check.svg",
  },
  {
    title: "Mercado de trabalho",
    paragraph:
      "Temos parcerias com mais de 500 empresas, que já contratam alunos e alunas da Kenzie. Ou seja, a entrada no mercado de trabalho é muito mais rápida para quem estuda com a gente.",
    image: "/images/icon-rocket.svg",
  },
  {
    title: "Comunidade Kenzie",
    paragraph:
      "Se você quer ter boas conexões, a Kenzie é a sua escolha. Contamos com centenas de alunos formados, além de instrutores que facilitam imensamente o networking.",
    image: "/images/icon-community.svg",
  },
];
