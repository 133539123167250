import Head from "next/head";
import { GetStaticProps } from "next";
import { useEffect, useState } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import styles from "../styles/pages/index.module.scss";
import Header from "../components/Header";
import Cta from "../components/Cta";
import KenzieNumbers from "../components/_home/KenzieNumbers";
import Testimonials from "../components/_home/Testimonials";
import Partners from "../components/_home/Partners";
import Methodology from "../components/_home/Methodology";
import Media from "../components/Media";
import Blog from "../components/_home/Blog";
import FloatLinks from "../components/FloatLinksButtonBlue";
import { BlogService } from "../services/blog-service";
import { blogApi } from "../services/axios";
import Image from "next/image";

type HomeProps = {
   blogPosts: Array<any>;
};

export default function Home({ blogPosts }: HomeProps) {
   const [isOpen, setIsOpen] = useState(false);
   useEffect(() => {
      AOS.init({
         disable: "mobile",
         duration: 700,
         easing: "ease-out-cubic",
         once: true,
      });

      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);

   useEffect(() => {
      const modalStatus = sessionStorage.getItem("status_modal_subscriber");

      !modalStatus && sessionStorage.setItem("status_modal_subscriber", "false");

      const handleScroll = () => {
         const isShowModalStatus: any = window.sessionStorage.getItem(
            "status_modal_subscriber"
         );
         const modalStatus = JSON.parse(isShowModalStatus);
         const scrollPosition = window.pageYOffset || document.documentElement.scrollTop;
         const triggerPosition = 800;

         const position = scrollPosition >= triggerPosition;

         !modalStatus && position ? setIsOpen(true) : null;
      };

      window.addEventListener("scroll", handleScroll);
   }, []);

   return (
      <>
         <Head>
            <title>Kenzie Academy Brasil: Curso de Programação Online</title>
            <meta
               name="description"
               content="Faça o Curso de Programação da Kenzie, entre no mercado rapidamente e ganhe altos salários!"
            />
            <meta
               name="twitter:title"
               content="Kenzie Academy Brasil: Curso de Programação Online"
            />
            <meta
               name="twitter:description"
               content="Faça o Curso de Programação da Kenzie, entre no mercado rapidamente e ganhe altos salários!"
            />
            <meta
               property="og:title"
               content="Kenzie Academy Brasil: Curso de Programação Online"
            />
            <link rel="canonical" href="https://kenzie.com.br/" />
         </Head>

         <Header />

         <main>
            <section className={styles.wrapper}>
               <div className={styles.background}>
                  <div className={styles.container}>
                     <div className={styles.contentContainer} data-aos="fade-right">
                        {/* <Image src="/logoBlackFriday.png" alt="Logo Black Friday" width={160} height={60} objectFit="contain"/> */}
                        <h1 className={styles.title}>
                           COMECE 2024 APRENDENDO A PROGRAMAR NA MELHOR ESCOLA DE TECNOLOGIA DO BRASIL
                        </h1>
                        <Cta
                           url="https://inscricao.kenzie.com.br"
                           maxWidthDesktop="28rem"
                           color="white"
                           passParams
                           text="Entrar na Lista de Espera"
                           centralized={false}
                           background
                        />
                     </div>
                  </div>
               </div>
            </section>
            <KenzieNumbers />
            <Testimonials mixedBackground />
            <Methodology />
            <Partners />
            <Media />
            {/* blogPosts ? <Blog blogPosts={blogPosts} /> : null */}
         </main>
         <FloatLinks />
      </>
   );
}

export const getStaticProps: GetStaticProps = async (context) => {
   const blogService = new BlogService(blogApi);
   const blogPosts = await blogService.listRecentPosts();

   return {
      props: {
         blogPosts: blogPosts,
      },
      revalidate: 60 * 60 * 24,
   };
};
