import Image from "next/image";
import Title from "../Title";
import styles from "./styles.module.scss";

export default function Media() {
  return (
    <section className={styles.wrapper}>
      <div className="container">
        <Title>Kenzie na mídia</Title>

        <div className={styles.logoContainer}>
          <a
            href="https://forbes.com.br/colunas/2019/12/exclusivo-kenzie-quer-substituir-universidade-no-brasil-com-cursos-tecnicos/"
            target="_blank"
            rel="noreferrer"
            data-aos="fade-down"
          >
            <Image
              src="/images/mediaIndex/forbes.webp"
              alt="Forbes"
              height="100"
              width="200"
            />
          </a>

          <a
            href="https://www1.folha.uol.com.br/mercado/2021/04/startups-testam-novas-formas-de-financiar-alunos-e-escolas.shtml"
            target="_blank"
            rel="noreferrer"
            data-aos="fade-down"
            data-aos-delay="100"
          >
            <Image
              src="/images/mediaIndex/folhadesp.webp"
              alt="Folha de São Paulo"
              height="100"
              width="200"
            />
          </a>

          <a
            href="https://www.tecmundo.com.br/mercado/211639-iniciar-carreira-area-tecnologia-altos-salarios.htm?utm_source=tecmundo_python"
            target="_blank"
            rel="noreferrer"
            data-aos="fade-down"
            data-aos-delay="200"
          >
            <Image
              src="/images/mediaIndex/tecmundo.webp"
              alt="Tecmundo"
              height="100"
              width="200"
            />
          </a>

          <a
            href="https://exame.com/pme/escola-de-programacao-kenzie-academy-recebe-aporte-de-r-8-milhoes/"
            target="_blank"
            rel="noreferrer"
            data-aos="fade-down"
            data-aos-delay="300"
          >
            <Image
              src="/images/mediaIndex/exame.webp"
              alt="Exame"
              height="100"
              width="200"
            />
          </a>
        </div>
      </div>
    </section>
  );
}
