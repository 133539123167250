export const numbers = [
  {
    title: "Alta",
    paragraph:
      "Empregabilidade.",
    image: "/images/home-icon1.png",
  },
  {
    title: "+3 MIL",
    paragraph: "Alunos que acreditam em nossa metodologia inovadora.",
    image: "/images/home-icon2.png",
  },
  {
    title: "+200 MIL",
    paragraph: "Seguidores em todas as redes sociais.",
    image: "/images/home-icon3.png",
  },
  {
    title: "+500",
    paragraph: "Empresas por todo o Brasil que contratam alunos da Kenzie.",
    image: "/images/home-icon4.png",
  },
];
