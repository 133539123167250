import styles from "./styles.module.scss";
import Image from "next/image";
import Title from "../../Title";
import { numbers } from "../../../shared/utils/numbers";

export default function KenzieNumbers() {
  return (
    <section>
      <div className={styles.container}>
        <Title color="#2d2d2d">O QUE ESPERAR DA KENZIE</Title>
        <ul>
          {numbers.map((d, index) => (
            <li data-aos="fade-down" key={`knumber${index}`}>
              <div>
                <Image src={d.image} alt="97%" width="48" height="48" />
                <strong>{d.title}</strong>
              </div>
              <p>{d.paragraph}</p>
            </li>
          ))}
        </ul>
      </div>
    </section>
  );
}
