import Image from "next/image";
import styles from "./styles.module.scss";
import Title from "../../Title";
import Cta from "../../Cta";

export default function Partners() {
  return (
    <div className={styles.background}>
      <section className="container">
        <Title>+500 empresas parceiras de contratação</Title>

        <div className={styles.partners}>
          <Image
            src="/images/partnersIndex/ifood.webp"
            alt="Logo iFood"
            data-aos="fade-down"
            height="100"
            width="200"
          />
          <Image
            src="/images/partnersIndex/madeiramadeira.webp"
            alt="Logo Madeira Madeira"
            data-aos="fade-down"
            height="100"
            width="200"
          />
          <Image
            src="/images/partnersIndex/picpay.webp"
            alt="Logo PicPay"
            data-aos="fade-down"
            data-aos-delay="100"
            height="100"
            width="200"
          />
          <Image
            src="/images/partnersIndex/boticario.webp"
            alt="Logo Grupo Boticário"
            data-aos="fade-down"
            data-aos-delay="100"
            height="100"
            width="200"
          />
          <Image
            src="/images/partnersIndex/ebanx.webp"
            alt="Logo Ebanx"
            data-aos="fade-down"
            data-aos-delay="200"
            height="100"
            width="200"
          />
          <Image
            src="/images/partnersIndex/james.webp"
            alt="Logo James"
            data-aos="fade-down"
            data-aos-delay="200"
            height="100"
            width="200"
          />
          <Image
            src="/images/partnersIndex/mirum.webp"
            alt="Logo Mirum"
            data-aos="fade-down"
            data-aos-delay="300"
            height="100"
            width="200"
          />
          <Image
            src="/images/partnersIndex/creditas.webp"
            alt="Logo Creditas"
            data-aos="fade-down"
            data-aos-delay="300"
            height="100"
            width="200"
          />
        </div>

        <Cta
          url="/parceiras"
          maxWidthDesktop="20.313rem"
          color="blue"
          passParams
          text="Conheça todas"
          isLocalUrl
        />
      </section>
    </div>
  );
}
